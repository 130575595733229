import { userEndpoints } from '@redux/features/user/api'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import isClient from '@components/common/isClient'
import CookiesKeys from '@constants/cookies/cookiesKeys'
import defaultParameters from '@constants/defaultParameters/defaultParameters'
import { LocalesList } from '@constants/locales/localesList'
import getAppConfig from '@services/config/config'
import { cookie } from '@services/cookies/cookies'
import userSettingsStorage from '@storageServices/storageEntities/userSettings'
import { ThemeNames } from '@themes/themes'

import type { ICommonAppState } from './types/commonAppState'

const userSettingsStorageData = userSettingsStorage.getValue()
const { publicRuntimeConfig } = getAppConfig()
const getInitTheme = () => {
  const themeValues = Object.values(ThemeNames)
  const localStorageTheme =
    userSettingsStorageData?.theme &&
    themeValues.includes(userSettingsStorageData.theme) &&
    userSettingsStorageData.theme
  const initEnvTheme =
    !localStorageTheme &&
    themeValues.findIndex(theme => theme === (publicRuntimeConfig.theme as ThemeNames)) > -1 &&
    publicRuntimeConfig.theme
  return (localStorageTheme ? localStorageTheme : initEnvTheme ? initEnvTheme : defaultParameters.theme) as ThemeNames
}

const initialState: ICommonAppState = {
  isPageInteraction: false,
  isRtl: isClient ? document.dir === 'rtl' : false,
  language: (cookie.getCookie(CookiesKeys.LANGUAGE) as LocalesList) || LocalesList.EN,
  theme: getInitTheme(),
}

export const commonAppSlice = createSlice({
  extraReducers: builder => {
    builder.addMatcher(userEndpoints.getCurrentUserInfo.matchFulfilled, (state, { payload }) => {
      if (payload?.uid) state.isPageInteraction = true
    })
  },
  initialState,
  name: 'commonApp',

  reducers: {
    setLanguage: (state, action: PayloadAction<LocalesList>) => {
      state.language = action.payload
    },
    setPageInteraction: (state, action: PayloadAction<boolean>) => {
      state.isPageInteraction = action.payload
    },
    setTheme: (state, action: PayloadAction<ThemeNames>) => {
      userSettingsStorage.addValues({ theme: action.payload })
      state.theme = action.payload
    },
  },
})

export const { setLanguage, setPageInteraction, setTheme } = commonAppSlice.actions

export default commonAppSlice.reducer
