import type { ThemeNames } from '@themes/themes'

import StorageHandler from '../storageHandler'

const userSettingsKey = 'userSettings'

type UserSettingsType = {
  theme?: ThemeNames
}

const userSettingsStorage = new StorageHandler<UserSettingsType>({ storageKey: userSettingsKey, storageType: 'locale' })

export default userSettingsStorage
